import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Row, Col, Typography } from "antd"

import Layout from "../../components/layout"
import Head from "../../components/head"
import BetaTestersLogos from "../../components/beta-testers-logos"
import IntegrationsLogos from "../../components/integrations-logos"
import ComingTag from "../../components/coming-tag"
import FreeTrialCta from "../../components/free-trial-cta"
import CtaRow from "../../components/cta-row"

const { Title, Paragraph, Text } = Typography

const Groupe = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      mainImage: file(relativePath: { eq: "groupes-filiales-image-0.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      oneImage: file(relativePath: { eq: "suivi-tresorerie-image-1.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      twoImage: file(relativePath: { eq: "suivi-tresorerie-image-2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      threeImage: file(relativePath: { eq: "anticiper-tresorerie-image-8.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      fourImage: file(relativePath: { eq: "multi-scenarios-image-5.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      pierreImage: file(relativePath: { eq: "pierre-cathelin.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100)
        }
      }
      hubvisoryImage: file(relativePath: { eq: "hubvisory-logo.png" }) {
        childImageSharp {
          gatsbyImageData(height: 35)
        }
      }
      arrowOneImage: file(relativePath: { eq: "arrow-down-1.png" }) {
        childImageSharp {
          gatsbyImageData(height: 70)
        }
      }
      arrowTwoImage: file(relativePath: { eq: "arrow-down-2.png" }) {
        childImageSharp {
          gatsbyImageData(height: 70)
        }
      }
      arrowThreeImage: file(relativePath: { eq: "arrow-down-3.png" }) {
        childImageSharp {
          gatsbyImageData(height: 90)
        }
      }
    }
  `)

  const description = "Logiciel de gestion de trésorerie pour PME. Pilotez votre trésorerie en temps réel grâce à la connexion bancaire. Réalisez des prévisions fiables pour anticiper et prendre les meilleures décisions business."

  return (
    <Layout>
      <Head
        title="Outil de trésorerie pour les Groupes et Filiales"
        description={description}
        canonical="https://rocketchart.co/solutions/groupes-et-filiales/"
      >
      </Head>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle" style={{ marginRight: 0, marginLeft: 0 }}>
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 13, offset: 0, order: 0 }}>
          <Title>Gérez la trésorerie d’un groupe multi-entités en toute simplicité</Title>
          <Paragraph style={{ color: "black" }}>Visualisez facilement la trésorerie consolidée du groupe et de ses filiales, avec la connexion multi-banques en temps réel. Automatisez vos reportings dans la devise que vous voulez.</Paragraph>
          <CtaRow />
        </Col>
        <Col xs={{ span: 16, offset: 4, order: 0 }} lg={{ span: 11, offset: 0, order: 1 }} justify="center">
          <GatsbyImage image={data.mainImage.childImageSharp.gatsbyImageData} alt="Pilotage multi-entités" className="rounded-image" />
        </Col>
      </Row>

      <Row className="content-container" gutter={40} type="flex" justify="center" style={{ marginTop: "120px" }}>
        <Col span={24} align='center'>
          <Text strong style={{ color: '#6a737d' }}>Ils pilotent leur trésorerie sur RocketChart</Text>
        </Col>
        <BetaTestersLogos />
      </Row>

      <Row className="content-container" type="flex" justify="center" style={{ marginTop: "80px" }}>
        <Col xs={24} md={16}>
          <div align='center'>
            <GatsbyImage image={data.arrowTwoImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down 2" />
          </div>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 0 }}>
          <GatsbyImage image={data.oneImage.childImageSharp.gatsbyImageData} alt="Consolidation des comptes bancaires" />
        </Col>
        <Col xs={{ span: 24, order: 0 }} lg={{ span: 12, order: 1 }}>
          <Title level={2}>Centralisez tous les comptes bancaires sur une plateforme</Title>
          <Paragraph style={{ color: "black" }}>Gagnez un temps considérable au quotidien avec un suivi en temps réel de la trésorerie consolidée du groupe. En 1 clic, visualisez dans le détail la position de chaque entité. Plus besoin de vous connecter aux différentes banques pour agrégé manuellement les données sur un tableau Excel.</Paragraph>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={24} lg={12}>
          <Title level={2}>Automatisez votre reporting interne avec des données fiables</Title>
          <Paragraph style={{ color: "black" }}>Laissez notre algorithme classer automatiquement toutes vos transactions à partir de vos catégories personnalisées. Obtenez des tableaux de trésorerie fiables pour chacune de vos entreprises et un récapitulatif consolidé pour le groupe.</Paragraph>
        </Col>
        <Col xs={24} lg={12}>
          <GatsbyImage image={data.twoImage.childImageSharp.gatsbyImageData} alt="Automatisation du reporting groupe" />
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 0 }}>
          <GatsbyImage image={data.threeImage.childImageSharp.gatsbyImageData} alt="Comparaison facturé vs budget vs réalité" />
        </Col>
        <Col xs={{ span: 24, order: 0 }} lg={{ span: 12, order: 1 }}>
          <ComingTag />
          <Title level={2}>Visualisez les comptes dans la devise que vous voulez</Title>
          <Paragraph style={{ color: "black" }}>Passez d’une devise à l’autre en 1 clic. Consolidez les performances de chaque entité du groupe dans la devise souhaitée. Visualisez les données sur une interface claire et synthétique, adaptée à votre fonctionnement.</Paragraph>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={24} lg={12}>
          <ComingTag />
          <Title level={2}>Exportez les données Groupes et Filiales en 1 clic</Title>
          <Paragraph style={{ color: "black" }}>Communiquez en interne simplement et gagnez du temps sur vos reporting grâce aux exports personnalisables. Exportez en 1 clic le tableau de trésorerie d’une entité ou de la vue consolidée pour compléter vos documents financiers.</Paragraph>
        </Col>
        <Col xs={24} lg={12}>
          <GatsbyImage image={data.fourImage.childImageSharp.gatsbyImageData} alt="Rapprochement bancaire facturation simplifié" />
        </Col>
      </Row>

      <Row className="content-container" type="flex" justify="center" style={{ marginTop: "80px", marginBottom: "80px" }}>
        <Col xs={24} md={16}>
          <div align='center'>
            <GatsbyImage image={data.arrowOneImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down" />
          </div>
        </Col>
        <Col span={12} align='center' style={{ marginTop: '20px' }}>
          <Title level={2}>Intégrez vos outils de facturation, ERP et logiciels de comptabilité</Title>
          <Paragraph style={{ color: "black" }}>Synchronisez en temps réel vos factures dans RocketChart pour générer automatiquement votre prévisionnel de trésorerie et automatiser le rapprochement banque / facture.</Paragraph>
        </Col>
        <Col span={24} align='center' style={{ marginTop: '20px' }}>
          <IntegrationsLogos />
        </Col>
      </Row>

      <Row className="content-container" type="flex" justify="center" style={{ marginTop: "0px", marginBottom: "60px" }}>
        <Col xs={24} md={16}>
          <div align='center'>
            <GatsbyImage image={data.arrowThreeImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down 3" />
          </div>
        </Col>
        <Col xs={24} align='center' style={{ marginTop: '20px' }}>
          <Title level={2}>Démarrez maintenant votre essai gratuit</Title>
          <FreeTrialCta />
        </Col>
      </Row>

    </Layout>
  )
}

export default Groupe